.more-info-view-container{
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .more-info-view-inner{
    padding: 10px;
    max-width: 365px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }
  .more-info-item {
    padding:2px;
    width: 100%;
    text-align: center;
    margin-top:10px;
    .description-container{
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: center;
      font-size: 12pt;
      .description{
        flex: 1;
      }

    }
    .icon-email-plane{
      display: flex;
      justify-content: center;
      margin: 10px 0px 10px;
      img{
        align-self: center;
        width:47px;
        height: 55px;
        border-radius: 8px;
      }
    }
    .icon-app{
      display: flex;
      justify-content: center;
      margin: 50px 10px 0px;
      img{
        align-self: center;
        width:47px;
        height: 47px;
        border-radius: 8px;
      }
    }
    .icon{
      img{
        width:47px;
        height: 47px;
        border-radius: 8px;
      }
    }
    .section-header{
      text-align: center;
      font-weight: bold;
      font-size: 14pt;
    }
  }
  .app-store-button-left {
    float:left;
  }
  .app-store-button-right {
    float: right;
  }
  .bold {
    font-weight:  bold;
  }
  .app-store-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    button{
      .apple-store-button{
        width: 120px;
      }
      .google-play-button{
        width: 150px;
      }
    }
  }
}




