.contentContainer{
    box-sizing: border-box;
    background-color: #F8F6FC;
    height: 100%;
  }
  
  .animationContent{
    display: flex;
    justify-content: center;
    max-height: 90vh;
  }
  
  .animationContainer {
    // aspect-ratio: 0.693 / 1;
    scale: 1;
    background-color: #F8F6FC !important;
    width: 100%;
    height: 100%;
  }
  
  .progressLoadBar{
    background-color: #604099;
    height: 4px;
  }
  
  .progressLoadBarContainer{
    width: 100%;
    padding: 1rem;
    text-align: center;
  }
  
  @media (min-width: 768px) {
    .animationContainer {
      // aspect-ratio: 0.693 / 1;
      max-height: 80vh !important;
      width:50vw !important;
      scale: 1;
      background-color: #F8F6FC !important;
    }
  }
  
  .animationContainer.hidden {
    visibility: hidden;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  