.top-container{
    /* min-height: 50px; */
    flex-grow: 0;
  }
  
  .top-container img {
    min-height: 45px;
  }
  
  .carousel-container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0px;
    box-sizing: border-box;
    max-height: 100vh;
    flex-direction: column;
    flex-grow: 1;
  }
  
  .slider-container{
    padding: 10px 0px;
  }
  
  .bottom-nav-container{
    background: #FFFFFF;
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    box-sizing: border-box;
    height: 68px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 0;
  }
  
  
  .bottom-nav-inner{
    display: flex;
    flex-direction: row;
    align-items: center;
  
    div {
      display: flex;
    }
  }
  
  .bottom-nav-icon{
    margin: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 42px;
  
    .label {
      font-weight:bold;
      font-size: 12px;
      min-height: 18px;
    }
  
    .icon {
      padding: 5px;
      width: auto;
    }
  }
  
  .unityViewSlide{
    margin:0 !important;
  }

  #viewer{
    width:512px;
    height:376px;
    border:1px solid #000;
    background-color:rgba(0,0,0,0.5);
  }