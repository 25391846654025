@font-face{
  font-family:"HeySunshine";
  src:url('https://content.hallmark.com/POD_Fonts/AnnettePrintMGE-Regular.ttf'); 
 }

 #fontPreloader{
  font-family: 'HeySunshine';
  position: absolute;
  left: -9000px;
 }
 
 #dgreciproot{
  width: 100%;
  margin: 0px;
  padding: 0px;
  background-color: #F8F6FC;
  height: 100%;
}

.recipientViewContainer{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-start;
}
