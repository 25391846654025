@import '@hallmark/web.styles.fonts.beam/beam.module.scss';

.example-beam-vf {
  font-family: 'BeamNewHMK-VF';
}

.example-beam-170 {
  font-family: 'BeamNewHMK-170';
}

.example-beam-114 {
  font-family: 'BeamNewHMK-114';
}

.example-beam-84 {
  font-family: 'BeamNewHMK-84';
}

* {
  padding: 0;
  margin: 0;
}

html,
body, 
#root {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'BeamNewHMK-114';
}